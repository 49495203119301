import React, { useEffect, useState } from 'react';
import api from '../api/api';
import ReactTable from './../common/UI/ReactTable';
import { isUserAdmin } from '../utils/helpers';

const AdminHome = () => {
  const [dashData, setDashData] = useState([]);
  const [seeAll, setSeeAll] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);

  useEffect(() => {
    if (isUserAdmin()) {
      getDashboardDate();
    }
  }, []);

  const getDashboardDate = async () => {
    api
      .fetchDashboardData()
      .then((res) => {
        setDashData(res.data.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const columns = [
    {
      Header: 'Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row?.original?.date}</span>;
      },
    },
    {
      Header: 'Total Orders',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row?.original?.total_orders}</span>;
      },
    },
    {
      Header: 'Total Sub Orders',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row?.original?.sub_orders}</span>;
      },
    },
    {
      Header: 'New Mounjaro Orders',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row?.original?.mounjaro_orders}</span>;
      },
    },
    {
      Header: 'New Wegovy Orders',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row?.original?.wegovy_orders}</span>;
      },
    },
    {
      Header: 'All Other Orders',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row?.original?.other_orders}</span>;
      },
    },
  ];

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  return (
    <>
      {isUserAdmin() ? (
        <div className="content_wrapper all_products_main">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="box_main">
                  <div className="header_top">
                    <h3>Dashboard</h3>
                  </div>
                  <div className="middle_main">
                    <ReactTable
                      title="Dashboard Data"
                      tableColumns={columns}
                      tableData={dashData}
                      seeAll={seeAll}
                      nextHandle={nextHandle}
                      prevHandle={prevHandle}
                      totalPage={totalPage}
                      pageNo={currentPageNo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminHome;
