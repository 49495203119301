import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError';
import { toastError, toastSuccess } from '../../UI/Toasts';
import CustomModal from '../../UI/CustomModal';
import ImageProductPreviewModal from '../../../components/addProducts/productDetails/ImageProductPreviewModal';
import DeleteConfirmationModal from '../../UI/CustomModal/deleteModal';

import plusBlueIcon from '../../../assets/images/plus-blue.svg';
import previewsIcon from '../../../assets/images/eye.svg';
import delIcon from '../../../assets/images/trash-white.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  getItemStyle,
  getListStyleSelected,
  reorder,
} from '../../../utils/productHelper';
import { imageDimentionValidator } from '../../../utils/helpers';

const ImageInput = (props) => {
  const {
    label,
    subLabel,
    name,
    id,
    classForLabelOuter,
    classForInputOuter,
    onChangeAction,
    imagesLimit,
    accept,
    value,
    productId,
    imageDimention,
    showPrimaryTag,
    isRequired,
  } = props;

  const [showImageModel, setShowImageModel] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [selectData, setSelectedData] = useState(false);

  const handleDelete = (imgArr, setFieldValue, name) => {
    let filteredArray = imgArr?.filter((item) => {
      return item.PrevUrl !== selectData;
    });
    setFieldValue(name, filteredArray, false);
    setShowDeleteModel(false);
  };

  return (
    <>
      <CustomModal
        title="Product Image"
        show={showImageModel}
        handleToggle={() => setShowImageModel(!showImageModel)}
      >
        <ImageProductPreviewModal
          cancel={() => setShowImageModel(false)}
          imageFile={selectData}
        />
      </CustomModal>
      <div className={`row ${classForLabelOuter}`}>
        <div className="col-12">
          {label && (
            <p className="img_des_1">
              {label}{' '}
              {isRequired ? <span className="text-danger">*</span> : null}
            </p>
          )}
          {subLabel && (
            <p className="img_des_2">
              {subLabel}{' '}
              {isRequired ? <span className="text-danger">*</span> : null}
            </p>
          )}
        </div>
      </div>

      <Field name={name} id={id} validate={false}>
        {(formik) => {
          const {
            field: { value, name },
            form: { setFieldValue, setTouched, touched, errors },
          } = formik;
          const onDragEndImage = (result) => {
            const { source, destination } = result;
            // dropped outside the list
            if (!destination) {
              return;
            }

            if (source.droppableId === destination.droppableId) {
              const photos = value;

              let prodImagesReordered = reorder(
                photos,
                source.index,
                destination.index,
              );

              prodImagesReordered = prodImagesReordered.map((el, index) => {
                return {
                  ...el,
                  order: index + 1,
                };
              });

              setFieldValue(name, prodImagesReordered, true);
            }
          };
          return (
            <>
              <DeleteConfirmationModal
                name="Product Image"
                handleToggle={() => setShowDeleteModel(!showDeleteModel)}
                cancel={() => setShowDeleteModel(false)}
                show={showDeleteModel}
                size="lg"
                handleDelete={() => handleDelete(value, setFieldValue, name)}
              />
              <div className={`d-flex mb-4 ${classForInputOuter}`}>
                <div className="mb-2">
                  <div className="upload_text">
                    <span>
                      <label style={{ cursor: 'pointer' }}>
                        <img
                          src={plusBlueIcon}
                          width="24"
                          height="24"
                          className="img-fluid custom_radius me-2"
                          alt="plus"
                        />
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          multiple
                          name="photos"
                          accept={accept}
                          onChange={(e) => {
                            if (e.target.files.length !== 0) {
                              let imgData = {
                                PrevUrl: URL.createObjectURL(e.target.files[0]),
                                imgData: e.target.files[0],
                              };
                              let img = new Image();
                              img.src = imgData.PrevUrl;
                              img.onload = function () {
                                let height = imageDimention?.height;
                                let width = imageDimention?.width;
                                const isImageDimention =
                                  imageDimentionValidator.call(
                                    this,
                                    height,
                                    width,
                                  );

                                if (!isImageDimention) {
                                  toastError(
                                    `Please select photo of size ${width}x${height}`,
                                  );
                                  return;
                                } else {
                                  let newImgArr = Object.values(
                                    e.target.files,
                                  ).map((imgData, index) => {
                                    return {
                                      PrevUrl: URL.createObjectURL(imgData),
                                      imgData,
                                      order: index,
                                    };
                                  });
                                  if (newImgArr.length !== 0) {
                                    if (
                                      newImgArr.length + value.length <=
                                      (imagesLimit || 1)
                                    ) {
                                      setFieldValue(
                                        name,
                                        [...value, ...newImgArr],
                                        false,
                                      );
                                    } else {
                                      toastError(
                                        `You can select only upto ${
                                          imagesLimit || 1
                                        } images!!`,
                                      );
                                    }
                                  }
                                  onChangeAction
                                    ? onChangeAction(name, [
                                        ...value,
                                        ...newImgArr,
                                      ])
                                    : null;
                                }
                              };
                            }
                          }}
                          onBlur={() => {
                            setTouched({ ...touched, [name]: true });
                          }}
                        />
                      </label>
                    </span>
                    Upload
                  </div>
                  {touched[name] && errors[name] && (
                    <TextError>{errors[name]}</TextError>
                  )}
                </div>
                <DragDropContext onDragEnd={onDragEndImage}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        className="d-flex"
                        ref={provided.innerRef}
                        style={{
                          ...getListStyleSelected(snapshot.isDraggingOver),
                          padding: '0px',
                        }}
                      >
                        {value.length
                          ? value.map((elem, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Draggable
                                    isDragDisabled={productId ? true : false}
                                    key={index}
                                    draggableId={`main${index}`}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                        )}
                                      >
                                        <div key={index} className="">
                                          <div className="position-relative hover_effect_main">
                                            <img
                                              src={elem.PrevUrl}
                                              alt="med1"
                                              style={{
                                                borderRadius: '16px',
                                                width: '150px',
                                                height: '150px',
                                              }}
                                            />
                                            <div className="hover_effect_child">
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  setSelectedData(elem.PrevUrl);
                                                  setShowImageModel(true);
                                                }}
                                              >
                                                <img
                                                  src={previewsIcon}
                                                  width="24"
                                                  height="24"
                                                  alt="preview"
                                                  className="mx-2"
                                                />
                                              </button>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  setSelectedData(elem.PrevUrl);
                                                  setShowDeleteModel(true);
                                                }}
                                              >
                                                <img
                                                  src={delIcon}
                                                  width="24"
                                                  height="18"
                                                  alt="trash"
                                                  className="mx-2"
                                                />
                                              </button>
                                            </div>
                                          </div>
                                          {index === 0 && showPrimaryTag ? (
                                            <span
                                              className="mx-4 p-1 text-primary mt-2"
                                              style={{
                                                fontSize: '12px',
                                                borderRadius: '32px',
                                                background: '#DCEFFF',
                                              }}
                                            >
                                              PRIMARY
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                </React.Fragment>
                              );
                            })
                          : null}
                        {/* To resolve Warning : Droppable setup issue */}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </>
          );
        }}
      </Field>
    </>
  );
};

export default ImageInput;
